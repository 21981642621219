<template>
  <v-text-field
    class="input-field header-fields"
    clearable
    flat
    hide-details
    :label="label"
    prepend-inner-icon="mdi-magnify"
    solo
    :value="value"
    @input="handleInput"
  />
</template>

<script>
export default {
  name: "DebouncedTextField",

  props: {
    label: {
      type: String,
      default: "",
    },
    input: {
      type: Function,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    timeoutRef: null,
  }),

  methods: {
    handleInput(e) {
      if (this.timeoutRef) clearTimeout(this.timeoutRef)
      this.timeoutRef = setTimeout(() => {
        this.input(e)
      }, 800)
    },
  },
}
</script>

<style lang="scss" scoped></style>
