<template>
  <v-row dense>
    <v-col cols="12" md="7">
      <DebouncedTextField
        :input="text => handleInput('ADD_VIEW_SEARCH_ASSIGNMENT', text)"
        label="Search Student (by email or  name)"
        :value="viewAssignments.searchStudent"
      />
    </v-col>
    <!-- <v-col cols="12" md="5">
      <v-select
        class="header-fields"
        clearable
        :disabled="listLoading"
        flat
        hide-details
        item-text="name"
        item-value="uuid"
        :items="batchChoices"
        label="Batch"
        solo
        :value="assignments.selectedBatch"
        @input="
          batchId => handleInput('ADD_ASSIGNMENT_SELECTED_BATCH', batchId)
        "
      />
    </v-col> -->
    <v-col cols="12" md="5">
      <v-select
        class="header-fields"
        clearable
        :disabled="listLoading"
        flat
        hide-details
        :items="statusList"
        label="Status"
        solo
        :value="viewAssignments.selectedStatus"
        @input="status => handleInput('ADD_VIEW_ASSIGNMENT_STATUS', status)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import DebouncedTextField from "@/components/shared/DebouncedTextField.vue"

export default {
  name: "ViewAssignmentSearch",

  components: { DebouncedTextField },

  props: {
    reset: {
      type: Function,
      required: true,
    },
    getAssignmentsData: {
      type: Function,
      required: true,
    },
  },

  data: () => ({
    assignmentId: null,
  }),

  computed: {
    ...mapGetters([
      "viewAssignments",
      "batchChoices",
      "statusList",
      "assignments",
      "listLoading",
    ]),
  },

  beforeMount: function() {
    this.assignmentId = this.$route.params.assignmentId
  },

  methods: {
    ...mapActions(["getViewAssignments"]),

    //  handleInput
    handleInput(request, value) {
      this.reset()
      this.$store.commit(request, value ? value : "")
      this.getViewAssignments(this.assignmentId)
      this.getAssignmentsData()
    },
  },
}
</script>

<style lang="scss" scoped></style>
