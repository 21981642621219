<template>
  <v-card :class="`${color}-shadow py-10 px-8 d-flex flex-column`" flat tile>
    <p class="text-body-2 darkCarbon--text font-weight-medium">
      {{ title }}
    </p>
    <div class="d-flex justify-space-between align-center">
      <div>
        <p class="darkBlue--text big-heading font-weight-regular">
          {{ body }}
          <!-- TODO: add slot here -->
          <!-- <v-btn text x-small to="/dashboard/assignments/home" color="primary">
            view <v-icon>mdi-chevron-right</v-icon>
          </v-btn> -->
        </p>
      </div>
      <div>
        <span :class="`icon-shadow-image ${color}-shadow-icon`">
          <v-avatar :color="`${color}-shade`" size="20" />
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "AnalyticsCard",

  props: {
    title: {
      type: String,
      required: true,
    },
    body: {
      type: [Number, String],
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    loading: Boolean,
  },
}
</script>

<style scoped lang="scss">
$green-color: #06f255;
$red-color: #ff574e;
$yellow-color: #ff9900;
$purple-color: #5f69f5;

.green-shadow {
  box-shadow: 2px 4px 6px rgba($green-color, 0.13) !important;
}
.red-shadow {
  box-shadow: 2px 4px 6px rgba($red-color, 0.13) !important;
}
.yellow-shadow {
  box-shadow: 2px 4px 6px rgba($yellow-color, 0.13) !important;
}
.purple-shadow {
  box-shadow: 2px 4px 6px rgba($purple-color, 0.13) !important;
}
.icon-shadow-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 20px;
  img {
    width: 15px;
  }
}
.green-shadow-icon {
  background: rgba($green-color, 0.13);
}
.red-shadow-icon {
  background: rgba($red-color, 0.13);
}
.yellow-shadow-icon {
  background: rgba($yellow-color, 0.13);
}
.purple-shadow-icon {
  background: rgba($purple-color, 0.13);
}
.icon-image {
  border-radius: 50%;
}

.green-shade {
  background: $green-color;
}
.red-shade {
  background: $red-color;
}
.yellow-shade {
  background: $yellow-color;
}
.purple-shade {
  background: $purple-color;
}
.big-heading {
  font-size: 48px !important;
}
</style>
