function formatAssignmentTitle(title, shortTitle) {
  if (!shortTitle || shortTitle === title) return title

  return `${shortTitle}: ${title}`
}

function formatCourses(courses) {
  if (!Array.isArray(courses)) {
    throw new Error("courses should be an array")
  }

  if (courses.length > 2) {
    return `${courses.slice(0, 2).join(", ")} +${courses.length - 2} more`
  }

  if (courses.length > 0) {
    return courses.join(", ")
  }

  return "-"
}

export default {
  filters: {
    formatAssignmentTitle,
    formatCourses,
  },
}
